/* eslint-disable max-len */
import React, { FC } from 'react';
import { Helmet } from 'react-helmet';

import { TextWrapper } from '../../components/common/TextWrapper';

import { StyledLink } from '../../components/common/Styled';
import { Header } from './Header';
import Divider from '../../components/common/Divider';

const Landing: FC = () => (
  <main>
    <Helmet>
      <title>John Oshalusi | Home </title>
      <meta name="description" content="Home" />
    </Helmet>
    <Header />
    <TextWrapper>
      I&apos;m a passionate software engineer. I enjoy building software products that people would
      love to use. I have a CS background and 7+ years of professional experience collaborating with
      creatives and development teams on executing their projects and ideas.
    </TextWrapper>
    <TextWrapper>
      I&apos;m currently working as a software engineer with{' '}
      <StyledLink href={process.env.REACT_APP_IOHK_URL} target="_blank">
        IOHK
      </StyledLink>
      . My favourite programming languages are Javascript, Typescript and Python, but I use a bit
      more languages.
    </TextWrapper>
    <TextWrapper>
      I am a big fan of simple interfaces and minimalistic design. I&apos;m intrigued by good music,
      outstanding movies, video games, fabulous food, and amazing conversations. I seek to inspire
      and be inspired, envision the unlikely, work hard for things that are worth it, and be
      surrounded by those who bring out the best in me.
    </TextWrapper>
    <TextWrapper>
      Wanna chat? I&apos;m{' '}
      <StyledLink href={process.env.REACT_APP_TWITTER_URL} target="_blank">
        @oshalusijohn
      </StyledLink>{' '}
      on Twitter. You can also{' '}
      <StyledLink href={`mailto:${process.env.REACT_APP_EMAIL}`}>email me</StyledLink>.
    </TextWrapper>
    {/* <NewsLetter /> */}
    <Divider />
  </main>
);

export default Landing;
